import React from "react"
import RedirectAuthPage from "../components/RedirectAuthPage/redirectAuthPage"
import { Auth } from "aws-amplify"
import { authConfig } from "../utils/authOptions"

Auth.configure(authConfig)

const Index = () => {
  return <RedirectAuthPage path="/" />
}

export default Index
